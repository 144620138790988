import React from 'react'
import pfp from '../../assets/pfp.JPEG';
import { Button } from '../../components/button/Button';
import '../../App.scss'

const Home = (props) => {
    return (
        <div className='content'>
            <div className='h-center'>
                <img className='pfp' src={pfp}/><br/>
                <b className="yellow major glitch" data-glitch="Cameron Gould">Cameron Gould</b>
                <p className="submajor">I make <span><a href='https://media.gouldcs.com'>tech content</a></span> on the internet.</p>
            </div>
            <br/>
            <div className='col'>
                <Button featured={true} href={'https://www.amazon.com/dp/1803247339/'}>Transformers book</Button>
                <Button featured={true} href={'https://amzn.to/3wsJ9ek'}>Learn Machine Learning!</Button>
                <Button href={'https://linkedin.com/in/cameron-gould'}>LinkedIn</Button>
                <Button href={'https://www.tiktok.com/@cameronstech?'}>TikTok</Button>
                <Button href={'https://www.instagram.com/cams.tech'}>Instagram</Button>
                <Button href={'https://twitter.com/camgouldcs'}>Twitter</Button>
                <Button href={'https://github.com/gouldcs'}>Github</Button>
            </div>
        </div>
    )
}

export default Home