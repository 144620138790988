import React from 'react'
import '../../App.scss'

export const Button = (props) => {
    return (
        <a
        className={props.featured ? 'featured' : 'link-button'}
        href={props.href}
        style={{margin:'10px 0px'}}
        >
            {
                props.featured ? `✦ ${props.children} ✦` : props.children
            }
        </a>
    )
}