import './App.scss';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom'
import Home from './routes/home/Home';
// import Tiktok from './routes/tiktok/Tiktok';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAK4BmB_XmWkhjDiiAgKYAoIkBSJaUXcL8",
  authDomain: "camslinks-c7a7e.firebaseapp.com",
  projectId: "camslinks-c7a7e",
  storageBucket: "camslinks-c7a7e.appspot.com",
  messagingSenderId: "981333270837",
  appId: "1:981333270837:web:f2a35ab4d936389daa2c7c",
  measurementId: "G-3J47YD4EJN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="root">
      <div className="main">
        <div className="content-window">
          <Router>
            <Routes>
              <Route path='/' element={<Home/>}/>
              {/* <Route path='/tiktok' element={<Tiktok/>}/> */}
            </Routes>
          </Router>
        </div>
      </div>
    </div>
  );
}

export default App;